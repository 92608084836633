.socialLinks {
  display: flex;
  align-items: center;
  gap: 0 32px;
  font-size: 16px;
}

.socialLink {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--color-text);
}