@use "~@ef-global/web-ui/scss/settings/mixins/breakpoints";

.proCyclingFooter {
  grid-area: footer;
  font-size: 12px;
  background-color: var(--footer-bg, var(--article-bg));
  transition: background-color 0.2s ease;
  padding-top: 64px;

  @include breakpoints.breakpoint(l) {
    padding-top: 128px;
  }

  &.themeInverse {
    --color-text: white;

    :global(.footer-contact-button) {
      --btn-color: #191919;
      --btn-bg: white;
    }
  }
}

.proCyclingFooter__colophon {
  border-top: 1px solid var(--color-divider);
  color: var(--color-text);
  padding: 16px 0;
  line-height: 14px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 16px;
}

.proCyclingFooter__links {
  display: flex;
  gap: 16px;
}

.proCyclingFooter__inner {
  grid-column: main-start / main-end;
}

.proCyclingFooter__main {
  display: flex;
  flex-direction: column;

  @include breakpoints.breakpoint(m) {
    padding-bottom: 48px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    gap: 0;
  }
}

.proCyclingFooter__logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 48px;
  fill: var(--color-text);

  @include breakpoints.breakpoint(m) {
    margin-bottom: 0;
  }

  .logo {
    width: 144px;
    @include breakpoints.breakpoint(m) {
      width: 194px;
    }
  }
}

.proCyclingFooter__social {
  padding: 0 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include breakpoints.breakpoint(m) {
    padding: 0;
  }

  @include breakpoints.breakpoint(m) {
    display: block;
  }
}
